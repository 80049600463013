import ApprovingRequestsTableStorage, {
  ApprovingRequestsStorageHeaderDisplayParam,
  ApprovingRequestsStorageHeaderWidthReport,
} from "applications/approving_requests/utilities/ApprovingRequestsTableStorage";
import { useBulkApproval } from "applications/requests/hooks/useBulkApproval";
import RequestsTableStorage, {
  RequestsStorageHeaderDisplayParam,
  RequestsStorageHeaderWidthReport,
} from "applications/requests/utilities/RequestsTableStorage";
import Tag from "applications/tags/components/Tag";
import {
  TableColumnControlCacheParams,
  TableColumnControlParam,
  TableColumnControlParams,
} from "components/renewaled_ui/ModalColumnControl/interface";
import { TableColumn } from "components/renewaled_ui/Table/interface/table";
import i18next from "i18n";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { RequestsSearchReportResponse } from "utilities/api/responses/requests/searchResults/report";
import formatter from "utilities/formatter";
import { isCorporatePlan } from "utilities/Utils";
import ReportRequestTypeFilter from "../../molecules/ReportRequestTypeFilter";
import { ReportTableRow, ReportTypes, RequestTableColumn } from "./interface";

interface Project {
  readonly name: string;
  readonly displayId: string;
}

interface UseHooks {
  readonly isShowColumnModal: boolean;
  readonly isEnableReportBulkApprove: boolean;
  readonly inProgressCreateApproveJobs: boolean;
  readonly disabledBulkApprovalButton: boolean;
  readonly selectedCount: number;
  readonly getSelectableColumns: () => TableColumnControlParam[];
  readonly getReactTableColumnParams: () => TableColumnControlParams;
  readonly getReactTableColumnParamsDefault: () => TableColumnControlParams;
  readonly onSetColumnInModal: (params: TableColumnControlCacheParams) => void;
  readonly getReports: () => ReportTableRow[];
  readonly getHeaderColumns: () => TableColumn[];
  readonly onClickShowModal: () => void;
  readonly onHideModal: () => void;
  readonly onFetchData: (
    startIndex: number,
    sizePerPage: number,
    background: boolean,
  ) => Promise<void>;
  readonly onSelectTable: (data: ReportTableRow, isSelected: boolean) => void;
  readonly onSelectAllTable: (isSelected: boolean) => void;
  readonly onBulkApprove: () => void;
}

interface HooksProps {
  readonly reports: RequestsSearchReportResponse[];
  readonly only?: string[];
  readonly except: string[];
  readonly selectedType?: "all" | "normal" | "settlement";
  readonly currentPage: number;
  readonly sizePerPage: number;
  readonly isLoading: boolean;
  readonly displayStatusForApprover: boolean;
  readonly isApprover: boolean;
  readonly isApprovingPage: boolean;
  readonly reportTypes: ReportTypes;
  readonly searchedProjectId: string;
  readonly searchedProjectName: string;
  readonly fetchData: (
    offset: number,
    limit: number,
    options?: { reset: boolean },
  ) => void;
  readonly onSortChange: (
    sortName: string,
    sortOrder: "ASC" | "DESC",
  ) => Promise<void>;
  readonly onPageChange: (page: number) => void;
  readonly onExportClick: (report: RequestsSearchReportResponse) => void;
  readonly onDeleteClick: (report: RequestsSearchReportResponse) => void;
  /** レポートタイプを変更された */
  readonly onChangeReportOfReportTypes: () => void;
  readonly onChangeNoTemporaryPaymentOfReportTypes: () => void;
  readonly onChangeTemporaryPaymentOfReportTypes: () => void;
}

/**
 * 経費申請テーブル: ロジック
 */
export const useHooks = (p: HooksProps): UseHooks => {
  const [oldReportTypes, setOldReportTypes] = useState<ReportTypes | null>(
    null,
  );
  /** モーダルを表示するか */
  const [isShowColumnModal, setIsShowColumnModal] = useState<boolean>(false);

  /**
   * キャッシュ: 表示するカラム
   */
  const getCacheDisplay = ():
    | ApprovingRequestsStorageHeaderDisplayParam[]
    | RequestsStorageHeaderDisplayParam[]
    | null => {
    if (p.isApprovingPage)
      return ApprovingRequestsTableStorage.data.headerDisplay.reports;
    return RequestsTableStorage.data.headerDisplay.reports;
  };

  /**
   * キャッシュ: カラムの横幅
   */
  const getCacheWidth = ():
    | ApprovingRequestsStorageHeaderWidthReport
    | RequestsStorageHeaderWidthReport => {
    if (p.isApprovingPage)
      return ApprovingRequestsTableStorage.data.headerWidth.report;
    return RequestsTableStorage.data.headerWidth.report;
  };

  const {
    inProgressCreateApproveJobs,
    bulkApprove,
    disabledBulkApprovalButton,
    selectedCount,
    selectedIds,
    unapprovableReportIds,
    onSelect,
    onSelectAll,
    isEnableReportBulkApprove,
  } = useBulkApproval({
    resetTable: (): void => {
      const optionsReportTypes = p?.reportTypes || {};
      const offset = 0;
      return p.fetchData(offset, p.sizePerPage, {
        ...optionsReportTypes,
        reset: true,
      });
    },
    requests: p.reports.filter((r) => r !== undefined),
    type: "report",
    isApprovingPage: p.isApprovingPage,
  });

  /**
   * ページ先頭のデータ取得
   */
  const fetch = useCallback((optionsReportTypes: ReportTypes) => {
    // 常に1ページ目に戻す
    const offset = 0;
    p.fetchData(offset, p.sizePerPage, { ...optionsReportTypes, reset: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * 申請種別切り替え時データを取得する
   */
  useEffect(() => {
    if (!oldReportTypes) {
      setOldReportTypes(p.reportTypes);
      return;
    }
    if (oldReportTypes === p.reportTypes) return;

    fetch(p.reportTypes);
    setOldReportTypes(p.reportTypes);
  }, [p.reportTypes, oldReportTypes, fetch]);

  const isPreAmountVisible = (): boolean => {
    return userPreferences.preference.requestTypes.some(
      (x) => x.type === "pre_report_request",
    );
  };

  /**
   * エクスポートが押された
   */
  const handleClickExport = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    report: RequestsSearchReportResponse,
  ): void => {
    // イベントバブリングを抑止
    e.stopPropagation();

    p.onExportClick(report);
  };

  /**
   * 削除が押された
   */
  const handleClickDelete = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    report: RequestsSearchReportResponse,
  ): void => {
    // イベントバブリングを抑止
    e.stopPropagation();

    p.onDeleteClick(report);
  };

  /**
   * データ取得のハンドラ
   */
  const onFetchData = async (
    startIndex: number,
    sizePerPage: number,
    background: boolean,
  ): Promise<void> => {
    await p.fetchData(startIndex, sizePerPage, { reset: !background });
  };

  /**
   * チェックボックスで全選択を押した
   */
  const onSelectAllTable = (isSelected: boolean): void => {
    const displayRows = p.reports.slice(
      (p.currentPage - 1) * p.sizePerPage,
      p.currentPage * p.sizePerPage,
    );
    const filterdRows = displayRows.filter((r) => {
      if (!r) return false;
      if (unapprovableReportIds.includes(r.id)) return false;
      return true;
    });
    // 表示中の行データのみを選択する
    onSelectAll(filterdRows, isSelected);
  };

  /**
   * チェックボックスを押した
   */
  const onSelectTable = (data: ReportTableRow, isSelected: boolean): void => {
    onSelect(data.response, isSelected);
  };

  /**
   * 削除ボタンのJSXを返す
   */
  const renderDeleteButton = (
    report: RequestsSearchReportResponse,
  ): JSX.Element | null => {
    const deletable = _.get(report, "permission.deletable", false);

    return !_.isNil(p.onDeleteClick) ? (
      <span>
        <button
          className={`btn btn-danger${deletable ? "" : " disabled"}`}
          onClick={(e): void => {
            handleClickDelete(e, report);
          }}
        >
          {i18next.t("commons.actions.delete")}
        </button>
      </span>
    ) : null;
  };

  /**
   * タグのJSXを返す
   */
  const formatRequestType = (
    needsTemporaryPayment: boolean | null,
  ): JSX.Element => {
    if (_.isNil(needsTemporaryPayment)) {
      return (
        <Tag
          name={i18next.t("reports.properties.reportTypes.report")}
          color="#ffcc99"
        />
      );
    }
    if (needsTemporaryPayment) {
      return (
        <Tag
          name={i18next.t("reports.properties.reportTypes.temporaryPayment")}
          color="#99ccff"
        />
      );
    }
    return (
      <Tag
        name={i18next.t("reports.properties.reportTypes.noTemporaryPayment")}
        color="#99ccff"
      />
    );
  };

  /**
   * プロジェクトのJSXを返す
   */
  const formatProjects = (projects: Project[] | null): JSX.Element | string => {
    return formatter.projectBySearch(
      projects,
      p.searchedProjectId,
      p.searchedProjectName,
    );
  };

  /**
   * カラムを表示するか
   */
  const displayColumn = (column: string): boolean => {
    let isVisible = true;
    if (p.only) {
      isVisible = _.includes(p.only, column);
    }
    if (p.except) {
      isVisible = !_.includes(p.except, column);
    }
    return isVisible;
  };

  /**
   * 一括承認を押した
   */
  const onBulkApprove = (): void => {
    bulkApprove();
  };

  /**
   * 絞り込みフィルターを非表示を押した
   */
  const onHideModal = (): void => {
    setIsShowColumnModal(false);
  };

  /**
   * 絞り込みモーダルを表示を押した
   */
  const onClickShowModal = (): void => {
    setIsShowColumnModal(true);
  };

  /**
   * ボタンのJSXを返す
   */
  const getButtonGroupJSX = (
    report: RequestsSearchReportResponse,
  ): JSX.Element => {
    return (
      <div className="report-btn-group row">
        <span>
          <button
            className="btn btn-accent"
            onClick={(e): void => {
              handleClickExport(e, report);
            }}
          >
            {i18next.t("commons.actions.output")}
          </button>
        </span>
        {renderDeleteButton(report)}
      </div>
    );
  };

  /**
   * 原本確認のJSXを返す
   */
  const getMatchedOriginalReceipt = (
    matchedOriginalReceipt: boolean,
  ): JSX.Element => {
    return (
      <span>
        {matchedOriginalReceipt ? <i className="fa fa-check fa-lg"></i> : ""}
      </span>
    );
  };

  /**
   * テーブルの表示内容を整形する
   */
  const getReports = (): ReportTableRow[] =>
    p.reports.map((r): ReportTableRow => {
      if (_.isNil(r)) return r;

      const statusForApprover = formatter.requestStatus({
        status: r.statusForApprover,
        label: r.statusForApprover,
        color: r.statusColorForApprover,
      });
      const status = formatter.requestStatus({
        status: r.status,
        label: r.status,
        color: r.statusColor,
      });
      const isSelected = selectedIds.includes(r.id);
      const isUnapprovable = unapprovableReportIds.includes(r.id);

      return {
        id: r.id,
        createdAt: formatter.date(r.createdAt),
        title: formatter.text(r.title),
        sequence: formatter.text(r.sequence),
        needsTemporaryPayment: formatRequestType(r.needsTemporaryPayment),
        approvalFlowName: formatter.text(r.approvalFlowName),
        applicant: formatter.text(r.user?.name || "", ""),
        projects: formatProjects(r.projects),
        amount: formatter.amount(r.amount),
        withholding: formatter.amount(r.withholding),
        preAmount: formatter.amount(r.preAmount, "-"),
        department: formatter.text(r.department?.name || ""),
        matchedOriginalReceipt: getMatchedOriginalReceipt(
          r.matchedOriginalReceipt,
        ),
        statusForApprover,
        status,
        buttons: getButtonGroupJSX(r),
        isSelected,
        isUnapprovable,
        response: r,
      };
    });

  /**
   * テーブルのヘッダーカラム
   */
  const headerColumns: RequestTableColumn[] = [
    {
      accessor: "createdAt",
      Header: i18next.t("reports.properties.requestDate"),
      width: getCacheWidth().createdAt || 100,
      minWidth: 10,
      defaultCanSort: false,
      isShow: displayColumn("createdAt"),
    },
    {
      accessor: "title",
      Header: i18next.t(
        `reports.properties.${
          isCorporatePlan() ? "reportTitle" : "personalReportTitle"
        }`,
      ),
      width: getCacheWidth().title || 100,
      minWidth: 10,
      defaultCanSort: false,
      isShow: displayColumn("title"),
    },
    {
      accessor: "sequence",
      Header: i18next.t("reports.properties.sequence"),
      width: getCacheWidth().sequence || 100,
      minWidth: 10,
      defaultCanSort: true,
      isShow: displayColumn("sequence"),
    },
    {
      accessor: "needsTemporaryPayment",
      Header: i18next.t("reports.properties.requestType"),
      width: getCacheWidth().needsTemporaryPayment || 100,
      minWidth: 10,
      defaultCanSort: false,
      isShow: displayColumn("sequence"),
      /**
       * 申請一覧テーブルにおいて、申請種別によって行をフィルタする
       * テーブルのヘッダ内に、ドロップダウンを表示する
       */
      filterContent: (
        <ReportRequestTypeFilter
          onChangeReportOfReportTypes={p.onChangeReportOfReportTypes}
          onChangeNoTemporaryPaymentOfReportTypes={
            p.onChangeNoTemporaryPaymentOfReportTypes
          }
          onChangeTemporaryPaymentOfReportTypes={
            p.onChangeTemporaryPaymentOfReportTypes
          }
          reportTypes={p.reportTypes}
        />
      ),
    },
    {
      accessor: "approvalFlowName",
      Header: i18next.t("reports.properties.approvalFlowName"),
      width: getCacheWidth().approvalFlowName || 100,
      minWidth: 10,
      defaultCanSort: false,
      isShow: displayColumn("approvalFlowName"),
    },
    {
      accessor: "applicant",
      Header: i18next.t("reports.properties.applicantName"),
      width: getCacheWidth().applicant || 100,
      minWidth: 10,
      defaultCanSort: false,
      isShow: displayColumn("userName"),
    },
    {
      accessor: "projects",
      Header: i18next.t("projects.project"),
      width: getCacheWidth().projects || 100,
      minWidth: 10,
      defaultCanSort: false,
      isShow: displayColumn("projects"),
    },
    {
      accessor: "amount",
      Header: i18next.t("reports.properties.amount"),
      width: getCacheWidth().amount || 100,
      minWidth: 10,
      defaultCanSort: false,
      dataAlign: "right",
      isShow: displayColumn("amount"),
    },
    {
      accessor: "withholding",
      Header: i18next.t("reports.properties.withholding"),
      width: getCacheWidth().withholding || 100,
      minWidth: 10,
      defaultCanSort: false,
      dataAlign: "right",
      isShow: displayColumn("withholding"),
    },
    {
      accessor: "preAmount",
      Header: i18next.t("reports.properties.preAmount"),
      width: getCacheWidth().preAmount || 100,
      minWidth: 10,
      defaultCanSort: false,
      dataAlign: "right",
      isShow: isPreAmountVisible() && displayColumn("preAmount"),
    },
    {
      accessor: "department",
      Header: i18next.t("transactions.properties.userDepartment"),
      width: getCacheWidth().department || 100,
      minWidth: 10,
      defaultCanSort: false,
      dataAlign: "right",
      isShow: p.displayStatusForApprover && displayColumn("department"),
    },
    {
      accessor: "matchedOriginalReceipt",
      Header: i18next.t("reports.properties.matchedOriginalReceipt"),
      width: getCacheWidth().matchedOriginalReceipt || 100,
      minWidth: 10,
      defaultCanSort: false,
      dataAlign: "center",
      isShow:
        userPreferences.isPaperlessPlan &&
        p.isApprover &&
        displayColumn("matchedOriginalReceipt"),
    },
    {
      accessor: "statusForApprover",
      Header: i18next.t("reports.properties.statusForApprover"),
      width: getCacheWidth().statusForApprover || 100,
      minWidth: 10,
      defaultCanSort: false,
      isShow: p.displayStatusForApprover && displayColumn("statusForApprover"),
    },
    {
      accessor: "status",
      Header: i18next.t("reports.properties.status"),
      width: getCacheWidth().status || 100,
      minWidth: 10,
      defaultCanSort: false,
      isShow: displayColumn("status"),
    },
  ];

  /**
   * テーブルのヘッダーカラム: 表示するカラムのみの配列
   */
  const getShowHeaderColumns = (): RequestTableColumn[] =>
    headerColumns.filter((c) => c.isShow);

  /**
   * テーブルのヘッダーカラム: 末尾に追加され表示形式を変更できない
   */
  const headerColumnsAdded: RequestTableColumn[] = [
    {
      accessor: "buttons",
      Header: "",
      width: getCacheWidth().buttons || 100,
      minWidth: 10,
      defaultCanSort: false,
      isShow: displayColumn("button"),
    },
  ];

  /**
   * 表示するカラムのデフォルト値
   */
  const displayAccessorsDefault = (): string[] => {
    const cacheDisplays = getCacheDisplay();
    const showColumns = getShowHeaderColumns();
    // キャッシュがなければそのまま表示する
    if (!cacheDisplays) return showColumns.map((h) => h.accessor);

    // キャッシュがある場合
    // キャッシュしてる項目を後に表示しない権限に変わる可能性があるため、表示項目に一致した場合のみ反映する
    const showCacheColumns = showColumns.filter((c) => {
      const cacheColum = cacheDisplays.find((d) => d.accessor === c.accessor);
      if (!cacheColum) return true;
      return cacheColum.isShow;
    });
    const cacheShowDisplays = showCacheColumns.map((c) => {
      return (
        cacheDisplays.find((d) => d.accessor === c.accessor) || {
          accessor: c.accessor,
          index: 0,
        }
      );
    });
    const sorterdCacheColumns = cacheShowDisplays.sort(
      (a, b) => a.index - b.index,
    );
    return sorterdCacheColumns.map((c) => c.accessor);
  };

  /**
   * 表示順序と表示するカラムの一覧
   */
  const [displayAccessors, setDisplayAccessors] = useState<string[]>(
    displayAccessorsDefault(),
  );

  /**
   * ヘッダーのカラムを返す
   */
  const getHeaderColumns = (): TableColumn[] => {
    const showColumns = getShowHeaderColumns();
    const filterColumns: RequestTableColumn[] = displayAccessors.map(
      (accessor) => {
        return (
          showColumns.find((s) => s.accessor === accessor) || {
            accessor,
            Header: "",
            width: 0,
            minWidth: 0,
            defaultCanSort: false,
            isShow: false,
            dataAlign: "left",
          }
        );
      },
    );

    // ボタンなどの末尾固定のカラム
    const showAddedColumns = headerColumnsAdded.filter((c) => c.isShow);
    const mergedColumns = [...filterColumns, ...showAddedColumns];

    return mergedColumns.map(
      (c): TableColumn => ({
        accessor: c.accessor,
        Header: c.Header,
        width: c.width,
        minWidth: c.minWidth,
        defaultCanSort: c.defaultCanSort,
        dataAlign: c.dataAlign,
        filterContent: c.filterContent,
      }),
    );
  };

  /**
   * モーダルで、テーブルカラムの表示内容を取得する
   */
  const getSelectableColumns = (): TableColumnControlParam[] => {
    return getShowHeaderColumns().map((h, index) => ({
      accessor: h.accessor,
      selectable: true,
      show: h.isShow,
      index,
      Header: h.Header,
    }));
  };

  /**
   * モーダルで、カラムの表示非表示を取得する
   */
  const getReactTableColumnParams = (): TableColumnControlParams => {
    const cacheDisplays = getCacheDisplay();
    if (!cacheDisplays) {
      // キャッシュがなければそのままカラムを表示する
      const arr = getShowHeaderColumns().map((h, index) => [
        h.accessor,
        {
          show: h.isShow,
          index,
        },
      ]);
      return Object.fromEntries(arr);
    }
    const arr = cacheDisplays.map((c) => [
      c.accessor,
      {
        show: c.isShow,
        index: c.index,
      },
    ]);
    return Object.fromEntries(arr);
  };

  /**
   * モーダルで、カラムの表示非表示の初期値を取得する
   */
  const getReactTableColumnParamsDefault = (): TableColumnControlParams => {
    // キャッシュがなければそのままカラムを表示する
    const arr = getShowHeaderColumns().map((h, index) => [
      h.accessor,
      {
        show: h.isShow,
        index,
      },
    ]);
    return Object.fromEntries(arr);
  };

  /**
   * モーダルで、表示順序と表示するものを変更した
   */
  const onSetColumnInModal = (params: TableColumnControlCacheParams): void => {
    const displayColumns = Object.keys(params).map((accessor) => ({
      accessor,
      index: params[accessor].index || 0,
      isShow: params[accessor].show,
    }));
    const sorterdColumns = displayColumns.sort((a, b) => a.index - b.index);

    // 現在の表示項目を更新する
    setDisplayAccessors(
      sorterdColumns.filter((c) => c.isShow).map((s) => s.accessor),
    );

    // キャッシュに保存する
    if (p.isApprovingPage) {
      // 承認ページ
      ApprovingRequestsTableStorage.set({
        sizePerPage: ApprovingRequestsTableStorage.data.sizePerPage,
        headerWidth: ApprovingRequestsTableStorage.data.headerWidth,
        headerDisplay: {
          ...ApprovingRequestsTableStorage.data.headerDisplay,
          reports: sorterdColumns,
        },
      });
    } else {
      // 申請ページ
      RequestsTableStorage.set({
        sizePerPage: RequestsTableStorage.data.sizePerPage,
        headerWidth: RequestsTableStorage.data.headerWidth,
        headerDisplay: {
          ...RequestsTableStorage.data.headerDisplay,
          reports: sorterdColumns,
        },
      });
    }
  };

  return {
    isShowColumnModal,
    isEnableReportBulkApprove,
    inProgressCreateApproveJobs,
    onBulkApprove,
    disabledBulkApprovalButton,
    selectedCount,
    getSelectableColumns,
    getReactTableColumnParams,
    getReactTableColumnParamsDefault,
    onSetColumnInModal,
    getReports,
    getHeaderColumns,
    onClickShowModal,
    onHideModal,
    onFetchData,
    onSelectTable,
    onSelectAllTable,
  };
};
