import React from "react";
import { TableCellProps } from "react-table";
import styled from "styled-components";

interface WrapperProps {
  readonly isSimpleDesignMode: boolean;
}
const Wrapper = styled.td<WrapperProps>`
  margin: 0;
  padding: 0;
  user-select: text;
  ${(p): string => (p.isSimpleDesignMode ? "" : "border-left: 1px solid #eee;")}

  a {
    padding: ${(p): string => (p.isSimpleDesignMode ? "0 8px" : "0 12px")};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 100%;
    height: 58px;
    line-height: 58px;
    color: inherit;
    cursor: pointer;
    text-decoration: none;
  }
`;

interface Props {
  readonly isSimpleDesignMode: boolean;
  readonly cell: React.ReactNode;
  /** react-tableのdomのprops */
  readonly tableCellProps: TableCellProps;
  readonly href: string;
}

/**
 * table td aタグ
 */
export const TdA: React.FC<Props> = (props: Props) => {
  const className = `td${
    props.tableCellProps.className ? ` ${props.tableCellProps.className}` : ""
  }`;
  const style = {
    ...props.tableCellProps.style,
    maxWidth: props.tableCellProps?.style?.width || "auto",
    minWidth: "10px",
    display: "table-cell",
  };

  return (
    <Wrapper
      {...props.tableCellProps}
      className={className}
      isSimpleDesignMode={props.isSimpleDesignMode}
      key={props.tableCellProps.key}
      role={props.tableCellProps.role}
      style={style}
    >
      <a href={props.href}>{props.cell}</a>
    </Wrapper>
  );
};
