import React, { useRef } from "react";
import { TableRowProps } from "react-table";
import styled from "styled-components";
import colors from "values/colors";

const lightGreen = "#d4eee8";

interface WrapperProps {
  /** 枠線がないシンプルなデザイン */
  readonly isSimpleDesignMode?: boolean;
  /** 選択状態である */
  readonly isSelected: boolean;
  /** ハイライト状態 */
  readonly isHighLight: boolean;
  /** つかみ状態 */
  readonly isDragging: boolean;
}
const Wrapper = styled.tr<WrapperProps>`
  border-bottom: 1px solid ${colors.renewaled.borderGrey};
  transition: 50ms;
  text-decoration: none;
  color: inherit;
  ${(p): string => (p.isDragging ? " opacity: 0.5;" : "")}
  background: ${(p): string => {
    // ハイライト状態なら背景つける
    if (p.isHighLight) return lightGreen;
    // 選択状態でなければ背景付けない
    if (!p.isSelected) return "inherit";
    // 背景の設定
    if (p.isSimpleDesignMode) return colors.renewaled.whiteHoverActive;
    return lightGreen;
  }};

  &:hover {
    background: ${(p): string => {
      // つかみ状態
      if (p.isDragging) return "white";
      if (p.isSimpleDesignMode) {
        if (p.isSelected) return colors.renewaled.whiteHoverActive;
        return colors.renewaled.whiteHover;
      }
      return lightGreen;
    }};
  }
  [data-sticky-td] {
    position: sticky;
    background-color: white;
  }
`;

interface Props {
  readonly isSimpleDesignMode: boolean;
  readonly children: JSX.Element | JSX.Element[];
  /** react-tableのdomのprops */
  readonly tableRowProps: TableRowProps;
  /** 選択状態にする */
  readonly isSelected: boolean;
  /** ハイライト状態にする */
  readonly isHighLight: boolean;
  /** つかみ状態 */
  readonly isDragging?: boolean;
}

/**
 * table tr
 */
export const TrA = React.forwardRef<HTMLAnchorElement, Props>((props, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;
  const className = `tr${props.isSelected ? " isSelected" : ""}${
    props.isDragging ? " isDragging" : ""
  }`;

  return (
    <Wrapper
      {...props.tableRowProps}
      className={className}
      ref={resolvedRef as React.RefObject<HTMLTableRowElement>}
      isSimpleDesignMode={props.isSimpleDesignMode}
      isSelected={props.isSelected}
      isHighLight={props.isHighLight}
      isDragging={props.isDragging || false}
      style={{
        ...props.tableRowProps.style,
        display: "table-row",
      }}
    >
      {props.children}
    </Wrapper>
  );
});
