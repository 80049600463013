import _get from "lodash/get";
import snakeCase from "lodash/snakeCase";

export function collectParams(state) {
  const requestTypes = Object.keys(state.requestType).filter(
    (x) => state.requestType[x].checked,
  );
  const forApproverStatus =
    state.status.forApprover !== "none" ? state.status.forApprover : null;
  let reportTypes = state.reportTypes;
  if (Object.values(reportTypes).every((value) => !value)) {
    reportTypes = {
      report: true,
      noTemporaryPayment: true,
      temporaryPayment: true,
    };
  }

  let statusForReport = Object.keys(state.status.forReport).filter(
    (x) => state.status.forReport[x],
  );

  const {
    preference: { useDkRequests, usePaymentRequest },
  } = userPreferences;
  if (!useDkRequests && usePaymentRequest) {
    // IP専用事業所の場合は、使用しない承認状況を除いた条件で検索を実行する
    const unusedStatusesForExclusiveIP = [
      "pendingTemporaryPayment",
      "pendingTemporaryPaymentRefund",
      "unsettled",
      "settled",
      "closed",
    ];
    statusForReport = statusForReport.filter(
      (x) => !unusedStatusesForExclusiveIP.includes(x),
    );
  }

  const genericFields =
    state.genericFields &&
    Array.isArray(state.genericFields) &&
    state.genericFields.filter((genericField) => genericField.value !== "");

  return {
    transactedAtFrom: state.transactedAtFrom,
    transactedAtTo: state.transactedAtTo,
    submittedAtFrom: state.submittedAtFrom,
    submittedAtTo: state.submittedAtTo,
    lastApprovedAtFrom: state.lastApprovedAtFrom,
    lastApprovedAtTo: state.lastApprovedAtTo,
    sequenceNum: state.sequenceNum,
    title: state.title,
    applicantName: state.applicantName,
    approverId: state.approver && state.approver.id,
    approverName: state.approverName,
    approvedApproverId: state.approvedApprover && state.approvedApprover.id,
    approvedApproverName: state.approvedApproverName,
    approvalFlowName: state.approvalFlowName,
    categoryId: state.categoryId,
    projectId: state.projectId,
    projectName: state.projectName,
    groupId: _get(state, "group.id"),
    requestTypes,
    status: {
      forReport: statusForReport.map(snakeCase),
      forApprover: forApproverStatus,
    },
    for: state.scope,
    sort: state.sort,
    includeChildDepartment: state.includeChildDepartment,
    reportTypes,
    matchedOriginalReceipt: state.matchedOriginalReceipt,
    notMatchedOriginalReceipt: state.notMatchedOriginalReceipt,
    invoice: {
      dueFrom: state.invoice.dueFrom,
      dueTo: state.invoice.dueTo,
      scheduleFrom: state.invoice.scheduleFrom,
      scheduleTo: state.invoice.scheduleTo,
      appropriatedFrom: state.invoice.appropriatedFrom,
      appropriatedTo: state.invoice.appropriatedTo,
      supplierName: state.invoice.supplierName,
      supplierCode: state.invoice.supplierCode,
      memo: state.invoice.memo,
      registratedNumber: state.invoice.registratedNumber,
      asInvoiceChecks: state.invoice.asInvoiceChecks,
      reportLabelIds: state.invoice.reportLabelIds,
      exclusionLabel: state.invoice.exclusionLabel,
    },
    genericFields,
  };
}
