import { TdA } from "components/renewaled_ui/Table/atoms/TdA";
import React from "react";
import { Cell as CellProps, TableCellProps } from "react-table";
import { Td } from "../../atoms/Td";

interface Props {
  readonly cells: CellProps[];
  readonly isSimpleDesignMode: boolean;
  /** リンク先を指定する */
  readonly href: string;
  /* リンクにしないセルのカラムIDを指定する */
  readonly noLinkColumnsIds?: string[];
}

/**
 * セルの一覧
 */
export const CellsA: React.FC<Props> = (props: Props) => {
  /**
   * 列毎のボディーのスタイル
   */
  const cellProps = (prop, { cell }): Partial<TableCellProps>[] => {
    return [
      prop,
      {
        style: {
          textAlign: cell.column.dataAlign || "left",
        },
      },
    ];
  };

  return (
    <>
      {props.cells.map((cell: CellProps) => {
        if (props.noLinkColumnsIds?.includes(cell.column.id)) {
          return (
            <Td
              isSimpleDesignMode={props.isSimpleDesignMode}
              tableCellProps={cell.getCellProps(cellProps)}
              key={cell.getCellProps().key}
              cell={cell.render("Cell")}
              onClick={(): void => void 0}
            />
          );
        }
        return (
          <TdA
            isSimpleDesignMode={props.isSimpleDesignMode}
            tableCellProps={cell.getCellProps(cellProps)}
            key={cell.getCellProps().key}
            cell={cell.render("Cell")}
            href={props.href}
          />
        );
      })}
    </>
  );
};
