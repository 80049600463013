import { RequiredTableDataProps } from "components/renewaled_ui/Table/interface/table";
import { RequestsSearchResult } from "utilities/api/responses/requests";

export const hasHref = <Data extends RequiredTableDataProps>(
  props,
): boolean => {
  const originalData = props.row.original as Data;
  const response = originalData.response as RequestsSearchResult;

  return props.href ?? response?.path;
};

export const getHref = <Data extends RequiredTableDataProps>(props): string => {
  const originalData = props.row.original as Data;

  if (props.href) {
    return `${props.href}/${originalData.id}`;
  }

  const response = originalData.response as RequestsSearchResult;

  if (!response?.path) {
    return "";
  }

  if (response.queryString) {
    return `${response.path}?${response.queryString}`;
  }

  return response.path as string;
};
