import { StateRequestsSearchCondition } from "applications/requests/interface/search";
import qs from "qs";
import { RequestsSearchResult } from "utilities/api/responses/requests";
import { RequestsSearchPRConsolidatedReportResponse } from "utilities/api/responses/requests/searchResults/paymentRequestsConsolidatedReport";
import { RequestsSearchPRProposalResponse } from "utilities/api/responses/requests/searchResults/paymentRequestsProposal";
import { RequestsSearchPRReportResponse } from "utilities/api/responses/requests/searchResults/paymentRequestsReport";
import { RequestsSearchReportResponse } from "utilities/api/responses/requests/searchResults/report";
import { snakecaseKeys } from "utilities/Utils";
import { collectParams } from "./utils";

export const buildRequestPath = (
  requestType: string,
  report: RequestsSearchResult,
): string => {
  switch (requestType) {
    case "report": {
      const preReportId = (report as RequestsSearchReportResponse).preReportId;

      if (preReportId) {
        return `/pre_reports/${preReportId}`;
      }

      return `/reports/${report.id}`;
    }

    case "pre_report":
      return `/pre_reports/${report.id}`;

    case "application":
      return `/applications/${report.id}`;

    case "payment_requests/report":
      return `/payment_requests/reports/${
        (report as RequestsSearchPRReportResponse).reportId
      }/from_approve`;

    case "payment_requests/proposal":
      return `/payment_requests/proposals/${
        (report as RequestsSearchPRProposalResponse).reportId
      }/from_approve`;

    case "payment_requests/consolidated_report":
      return `/payment_requests/consolidated_reports/${
        (report as RequestsSearchPRConsolidatedReportResponse).reportId
      }`;

    default:
      throw new Error("Failed to build path for the report.");
  }
};

export const buildApprovingRequestPath = (
  requestType: string,
  report: RequestsSearchResult,
): string => {
  switch (requestType) {
    case "report": {
      const preReportId = (report as RequestsSearchReportResponse).preReportId;

      if (preReportId) {
        return `/approving_pre_reports/${preReportId}`;
      }

      return `/approving_reports/${report.id}`;
    }

    case "pre_report":
      return `/approving_pre_reports/${report.id}`;

    case "application":
      return `/approving_applications/${report.id}`;

    case "payment_requests/report":
      return `/payment_requests/reports/${
        (report as RequestsSearchPRReportResponse).reportId
      }/from_approve`;

    case "payment_requests/proposal":
      return `/payment_requests/proposals/${
        (report as RequestsSearchPRProposalResponse).reportId
      }/from_approve`;

    case "payment_requests/consolidated_report":
      return `/payment_requests/consolidated_reports/${
        (report as RequestsSearchPRConsolidatedReportResponse).reportId
      }`;

    default:
      throw new Error("Failed to build path for the report.");
  }
};

export const buildQueryString = (
  requestType: string,
  params: StateRequestsSearchCondition = {} as StateRequestsSearchCondition,
): string => {
  let query = {};

  if (params !== null) {
    query = snakecaseKeys(collectParams(params));
  }

  return qs.stringify(
    { ...query, request_type: requestType }, // eslint-disable-line camelcase
    { arrayFormat: "brackets" },
  );
};
