import { TableNoData } from "components/renewaled_ui/Table/atoms/TableNoData";
import { RequiredTableDataProps } from "components/renewaled_ui/Table/interface/table";
import { DraggableRow } from "components/renewaled_ui/Table/molecules/DraggableRow";
import { Row } from "components/renewaled_ui/Table/molecules/Row";
import React from "react";
import {
  Row as TableRow,
  TableBodyPropGetter,
  TableBodyProps,
} from "react-table";
import styled from "styled-components";

interface TbodyProps {
  /** テーブルボディーのfont-weight */
  readonly bodyFontWeight?: 400 | 500 | 600;
}
const Wrapper = styled.tbody<TbodyProps>`
  font-weight: ${(p): number => p.bodyFontWeight || 400};
`;

interface Props<Data> {
  /** テーブルデータ */
  readonly data: Data[];
  /** テーブルボディーのfont-weight */
  readonly bodyFontWeight?: 400 | 500 | 600;
  /** onRowClickがあるか(行をクリック可能か) */
  readonly hasOnRowClick?: boolean;
  /** 行をクリックした際の遷移先(指定した場合aリンクになる) */
  readonly href?: string;
  /** ボディー上部に追加される要素 */
  readonly bodyElement?: JSX.Element;
  /** データ読み込み中か */
  readonly isLoading?: boolean;
  /** データ読み込み中か */
  readonly columnLength: number;
  /** ページのROW */
  readonly page: TableRow[];
  /** 詳細閲覧中データ */
  readonly viewingData?: Data;
  /** 行のドラッグ&ドロップを有効にするか */
  readonly useRowDragAndDrop?: boolean;
  /** 行をクリックした際のハンドラ */
  readonly onRowClick?: (data: Data) => void;
  /** 列情報 */
  readonly prepareRow: (row: TableRow) => void;
  /** 行移動時のハンドラ */
  readonly onMove?: (dragIndex: number, targetIndex: number) => Promise<void>;
  /** 行のドラッグ&ドロップを有効にするか */
  // eslint-disable-next-line @typescript-eslint/ban-types
  readonly getTableBodyProps: (
    propGetter?: TableBodyPropGetter<object> | undefined,
  ) => TableBodyProps;
  /** 枠線がないシンプルなデザイン */
  readonly isSimpleDesignMode: boolean;
  /* リンクにしないセルのカラムIDを指定する */
  readonly noLinkColumnsIds?: string[];
}

/**
 * テーブルボディ
 */
export const TableBody = <Data extends RequiredTableDataProps>(
  props: Props<Data>,
): JSX.Element => {
  return (
    <Wrapper
      className="tbody"
      bodyFontWeight={props.bodyFontWeight}
      {...props.getTableBodyProps()}
    >
      {/* データがない場合 */}
      {!props.page.length && (
        <TableNoData
          isLoading={props.isLoading}
          columnLength={props.columnLength}
        />
      )}

      {/* 追加要素がある場合 */}
      {props.bodyElement}

      {props.page.map((row, i) => {
        // ドラッグ可能な行
        if (props.useRowDragAndDrop && props.onMove) {
          return (
            <DraggableRow
              key={`table-row-${i}`}
              data={props.data}
              row={row}
              prepareRow={props.prepareRow}
              onRowClick={props.onRowClick}
              href={props.href}
              viewingData={props.viewingData}
              index={i}
              onMove={props.onMove}
              isSimpleDesignMode={props.isSimpleDesignMode}
              hasOnRowClick={props.hasOnRowClick}
            />
          );
        }

        // 通常の行
        return (
          <Row
            key={`table-row-${i}`}
            data={props.data}
            row={row}
            prepareRow={props.prepareRow}
            onRowClick={props.onRowClick}
            href={props.href}
            viewingData={props.viewingData}
            isSimpleDesignMode={props.isSimpleDesignMode}
            hasOnRowClick={props.hasOnRowClick}
            noLinkColumnsIds={props.noLinkColumnsIds}
          />
        );
      })}
    </Wrapper>
  );
};
