import ApprovingRequestsTableStorage, {
  ApprovingRequestsTableStorageParam,
} from "applications/approving_requests/utilities/ApprovingRequestsTableStorage";
import { PRConsolidatedReportRequestTable } from "applications/payment_requests/requests/components/ConsolidatedReportRequestTable";
import { PRReportRequestTable } from "applications/payment_requests/requests/components/ReportRequestTable";
import { PRRequestTable } from "applications/payment_requests/requests/components/RequestTable";
import { ConsolidatedReportRequestTableSpecification as PRConsolidatedReportRequestTableSpecification } from "applications/payment_requests/requests/specifications/ConsolidatedReportRequestTableSpecification";
import { ProposalRequestTableSpecification as PRProposalRequestTableSpecification } from "applications/payment_requests/requests/specifications/ProposalRequestTableSpecification";
import { ReportRequestTableSpecification as PRReportRequestTableSpecification } from "applications/payment_requests/requests/specifications/ReportRequestTableSpecification";
import ApplicationTable from "applications/requests/components/organisms/ApplicationTable";
import PreReportTable from "applications/requests/components/organisms/PreReportTable";
import RequestReportTable from "applications/requests/components/organisms/RequestReportTable";
import { StateRequestsSearchCondition } from "applications/requests/interface/search";
import RequestsTableStorage, {
  RequestsTableStorageParam,
} from "applications/requests/utilities/RequestsTableStorage";
import React, { PureComponent } from "react";
import {
  RequestsSearchResult,
  RequestsSearchResults,
  RequestsSearchTabType,
} from "utilities/api/responses/requests";
import { RequestsSearchApplicationResponse } from "utilities/api/responses/requests/searchResults/application";
import { RequestsSearchPRConsolidatedReportResponse } from "utilities/api/responses/requests/searchResults/paymentRequestsConsolidatedReport";
import { RequestsSearchPRProposalResponse } from "utilities/api/responses/requests/searchResults/paymentRequestsProposal";
import { RequestsSearchPRReportResponse } from "utilities/api/responses/requests/searchResults/paymentRequestsReport";
import { RequestsSearchPreReportResponse } from "utilities/api/responses/requests/searchResults/preReport";
import { RequestsSearchReportResponse } from "utilities/api/responses/requests/searchResults/report";
import ApiPaymentRequests from "utilities/api_payment_requests";

export interface Props {
  readonly userType: "admin" | "approver" | "user";
  readonly typeId: string;
  readonly type: RequestsSearchTabType;
  readonly data: RequestsSearchResults;
  readonly currentPage: number;
  readonly sizePerPage: number;
  readonly isLoading: boolean;
  readonly isApprovingPage: boolean;
  readonly tabName: string;
  readonly searchCondition: StateRequestsSearchCondition;
  readonly noLinkColumnsIds: string[];
  readonly fetchData: (
    typeId: string,
    searchCondition: StateRequestsSearchCondition,
    offset: number,
    limit: number,
    reset: boolean,
  ) => void;
  readonly onSortChange: (
    sortName: string,
    sortOrder: "ASC" | "DESC",
    searchCondition: StateRequestsSearchCondition,
    typeId: string,
    currentPage: number,
    limit: number,
  ) => void;
  readonly openReport: (
    type: RequestsSearchTabType,
    row: RequestsSearchResult,
    param: StateRequestsSearchCondition,
  ) => void;
  readonly onPageChange: (typeId: string, page: number) => void;
  readonly onDeleteClick: (
    type: RequestsSearchTabType,
    row: RequestsSearchResult,
    reportTypeId: string,
  ) => void;
  readonly onExportClick: (
    type: RequestsSearchTabType,
    row: RequestsSearchReportResponse | RequestsSearchPreReportResponse,
  ) => void;
  readonly onChangeSearchCondition: (reportType: string, type: string) => void;
  readonly onChangeReportOfReportTypes: () => void;
  readonly onChangeNoTemporaryPaymentOfReportTypes: () => void;
  readonly onChangeTemporaryPaymentOfReportTypes: () => void;
  readonly onChangeWidthRequestReportTable: (id: string, width: number) => void;
  readonly onChangeWidthPreReportTable: (id: string, width: number) => void;
  readonly onChangeWidthApplication: (id: string, width: number) => void;
  readonly onChangeWidthPRReportRequestTable: (
    id: string,
    width: number,
  ) => void;
  readonly onChangeWidthPRRequestTable: (id: string, width: number) => void;
  readonly onChangeWidthPRConsolidatedReportRequestTable: (
    id: string,
    width: number,
  ) => void;
  readonly onSizePerPageList: (sizePerPage: number, typeId: string) => void;
}

/**
 * 申請ページ,承認ページで使用するテーブル
 */
export default class TableTabContents extends PureComponent<Props> {
  /** カラム表示非表示設定: 支払依頼申請 */
  private pRReportRequestTableSpecification;

  /** カラム表示非表示設定: 稟議申請 */
  private pRProposalRequestTableSpecification;

  /** カラム表示非表示設定: 一括支払依頼申請 */
  private pRConsolidatedReportRequestTableSpecification;

  /**
   * Propsのデフォルト値
   */
  public static defaultProps = {
    data: [],
    searchCondition: {},
  };

  constructor(props: Props) {
    super(props);

    this.fetchData = this.fetchData.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.filterReportByRequestType = this.filterReportByRequestType.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleExportClick = this.handleExportClick.bind(this);
    this.pRReportRequestTableSpecification =
      new PRReportRequestTableSpecification(this.forApprover);
    this.pRProposalRequestTableSpecification =
      new PRProposalRequestTableSpecification(this.forApprover);
    this.pRConsolidatedReportRequestTableSpecification =
      new PRConsolidatedReportRequestTableSpecification(this.forApprover);
  }

  /**
   * データを取得する
   */
  fetchData(
    offset: number,
    limit: number,
    options: { reset: boolean } = { reset: false },
  ): void {
    const searchCondition = { ...this.props.searchCondition, ...options };
    const reset = options.reset || false;

    return this.props.fetchData(
      this.props.typeId,
      searchCondition,
      offset,
      limit,
      reset,
    );
  }

  /**
   * ソートをする
   */
  async onSortChange(
    sortName: string,
    sortOrder: "ASC" | "DESC",
  ): Promise<void> {
    const cacheParams:
      | RequestsTableStorageParam
      | ApprovingRequestsTableStorageParam = this.props.isApprovingPage
      ? ApprovingRequestsTableStorage.data
      : RequestsTableStorage.data;
    const limit = cacheParams.sizePerPage;
    await this.props.onSortChange(
      sortName,
      sortOrder,
      this.props.searchCondition,
      this.props.typeId,
      this.props.currentPage,
      limit,
    );
  }

  /**
   * レポートタイプによって、フィルターをかける
   */
  filterReportByRequestType(type: string): void {
    this.props.onChangeSearchCondition("reportType", type);
  }

  /**
   * ページを変えた
   */
  handlePageChange(page: number): void {
    this.props.onPageChange(this.props.typeId, page);
  }

  /**
   * 行をクリックした
   */
  handleRowClick(row: RequestsSearchResult): void {
    this.props.openReport(this.props.type, row, this.props.searchCondition);
  }

  /**
   * 削除を押した
   */
  handleDeleteClick(row: RequestsSearchResult): void {
    this.props.onDeleteClick(this.props.type, row, this.props.typeId);
  }

  /**
   * エクスポートを押した
   */
  handleExportClick(
    row: RequestsSearchReportResponse | RequestsSearchPreReportResponse,
  ): void {
    this.props.onExportClick(this.props.type, row);
  }

  /**
   * 承認者であるか
   */
  get forApprover(): boolean {
    return (
      this.props.userType === "approver" || this.props.userType === "admin"
    );
  }

  /**
   * オプションを返す
   */
  get exceptOptions(): string[] {
    const options = {
      userName: true,
      check: true,
      projects: true,
      projectsInPreReport: true,
      approvalFlowName: true,
      withholding: true,
    };

    const { preference } = userPreferences;

    if (this.forApprover) {
      options.userName = false;
    }

    if (this.props.type === "report" || this.props.type === "pre_report") {
      options.check = false;

      if (preference.inputProject && preference.showTransactionsProject) {
        options.projects = false;
      }
      if (
        preference.inputProject &&
        preference.showTransactionsProject &&
        preference.inputProjectInPreReport
      ) {
        options.projectsInPreReport = false;
      }
      if (preference.showWithholding) {
        options.withholding = false;
      }
    }

    if (preference.showApprovalFlowName) {
      options.approvalFlowName = false;
    }

    return Object.keys(options).filter((x) => options[x]);
  }

  render(): JSX.Element | null {
    switch (this.props.type) {
      /** 経費精算 */
      case "report":
        return (
          <RequestReportTable
            currentPage={this.props.currentPage}
            sizePerPage={this.props.sizePerPage}
            isLoading={this.props.isLoading}
            noLinkColumnsIds={this.props.noLinkColumnsIds}
            displayStatusForApprover={this.forApprover}
            fetchData={this.fetchData}
            onSortChange={this.onSortChange}
            onPageChange={this.handlePageChange}
            onDeleteClick={this.handleDeleteClick}
            onExportClick={this.handleExportClick}
            reports={this.props.data as RequestsSearchReportResponse[]}
            except={this.exceptOptions}
            isApprover={this.forApprover}
            onChangeReportOfReportTypes={this.props.onChangeReportOfReportTypes}
            onChangeNoTemporaryPaymentOfReportTypes={
              this.props.onChangeNoTemporaryPaymentOfReportTypes
            }
            onChangeTemporaryPaymentOfReportTypes={
              this.props.onChangeTemporaryPaymentOfReportTypes
            }
            reportTypes={this.props.searchCondition.reportTypes}
            searchedProjectId={this.props.searchCondition.searchedProjectId}
            searchedProjectName={this.props.searchCondition.searchedProjectName}
            isApprovingPage={this.props.isApprovingPage}
            onColumnWidthChange={this.props.onChangeWidthRequestReportTable}
            onSizePerPageList={(sizePerPage: number): void =>
              this.props.onSizePerPageList(sizePerPage, this.props.typeId)
            }
          />
        );
      /** 事前申請 */
      case "pre_report":
        return (
          <PreReportTable
            displayApplicant={this.forApprover}
            displayButtons={true}
            currentPage={this.props.currentPage}
            sizePerPage={this.props.sizePerPage}
            isLoading={this.props.isLoading}
            noLinkColumnsIds={this.props.noLinkColumnsIds}
            displayStatusForApprover={this.forApprover}
            fetchData={this.fetchData}
            onSortChange={this.onSortChange}
            onPageChange={this.handlePageChange}
            onDeleteClick={this.handleDeleteClick}
            onExportClick={this.handleExportClick}
            preReports={this.props.data as RequestsSearchPreReportResponse[]}
            isApprover={this.forApprover}
            except={this.exceptOptions}
            sequenceSortable={true}
            isApprovingPage={this.props.isApprovingPage}
            displayProject={true}
            displayStatus={true}
            displayTemporaryPaymentDueAt={false}
            onColumnWidthChange={this.props.onChangeWidthPreReportTable}
            onSizePerPageList={(sizePerPage: number): void =>
              this.props.onSizePerPageList(sizePerPage, this.props.typeId)
            }
          />
        );
      /** 汎用申請 */
      case "application":
        return (
          <ApplicationTable
            displayApplicant={this.forApprover}
            displayButtons={true}
            currentPage={this.props.currentPage}
            sizePerPage={this.props.sizePerPage}
            isLoading={this.props.isLoading}
            noLinkColumnsIds={this.props.noLinkColumnsIds}
            displayStatusForApprover={this.forApprover}
            fetchData={this.fetchData}
            onSortChange={this.onSortChange}
            onPageChange={this.handlePageChange}
            onDeleteClick={this.handleDeleteClick}
            applications={
              this.props.data as RequestsSearchApplicationResponse[]
            }
            except={this.exceptOptions}
            isApprovingPage={this.props.isApprovingPage}
            tabName={this.props.tabName}
            onColumnWidthChange={this.props.onChangeWidthApplication}
            onSizePerPageList={(sizePerPage: number): void =>
              this.props.onSizePerPageList(sizePerPage, this.props.typeId)
            }
          />
        );
      /** 支払依頼申請 */
      case "payment_requests/report":
        return (
          <PRReportRequestTable
            currentPage={this.props.currentPage}
            sizePerPage={this.props.sizePerPage}
            noLinkColumnsIds={this.props.noLinkColumnsIds}
            deleteApi={ApiPaymentRequests.reportRequests.destroy}
            i18nCustomKeys={{ amount: "paidAmount" }}
            fetch={this.fetchData}
            isLoading={this.props.isLoading}
            onPageChange={this.handlePageChange}
            onSortChange={this.onSortChange}
            requests={this.props.data as RequestsSearchPRReportResponse[]}
            searchedProjectId={this.props.searchCondition.searchedProjectId}
            searchedProjectName={this.props.searchCondition.searchedProjectName}
            specification={this.pRReportRequestTableSpecification}
            isApprovingPage={this.props.isApprovingPage}
            onColumnWidthChange={this.props.onChangeWidthPRReportRequestTable}
            onSizePerPageList={(sizePerPage: number): void =>
              this.props.onSizePerPageList(sizePerPage, this.props.typeId)
            }
          />
        );
      /** 稟議申請 */
      case "payment_requests/proposal":
        return (
          <PRRequestTable
            currentPage={this.props.currentPage}
            sizePerPage={this.props.sizePerPage}
            noLinkColumnsIds={this.props.noLinkColumnsIds}
            deleteApi={ApiPaymentRequests.proposalRequests.destroy}
            fetch={this.fetchData}
            isLoading={this.props.isLoading}
            onPageChange={this.handlePageChange}
            onSortChange={this.onSortChange}
            requests={this.props.data as RequestsSearchPRProposalResponse[]}
            searchedProjectId={this.props.searchCondition.searchedProjectId}
            searchedProjectName={this.props.searchCondition.searchedProjectName}
            specification={this.pRProposalRequestTableSpecification}
            isApprovingPage={this.props.isApprovingPage}
            onColumnWidthChange={this.props.onChangeWidthPRRequestTable}
            onSizePerPageList={(sizePerPage: number): void =>
              this.props.onSizePerPageList(sizePerPage, this.props.typeId)
            }
          />
        );
      /** 一括支払依頼申請 */
      case "payment_requests/consolidated_report":
        return (
          <PRConsolidatedReportRequestTable
            currentPage={this.props.currentPage}
            sizePerPage={this.props.sizePerPage}
            noLinkColumnsIds={this.props.noLinkColumnsIds}
            deleteApi={ApiPaymentRequests.consolidatedReportRequests.destroy}
            i18nCustomKeys={{ amount: "paidAmount" }}
            fetch={this.fetchData}
            isLoading={this.props.isLoading}
            onPageChange={this.handlePageChange}
            onSortChange={this.onSortChange}
            requests={
              this.props.data as RequestsSearchPRConsolidatedReportResponse[]
            }
            searchedProjectId={this.props.searchCondition.searchedProjectId}
            searchedProjectName={this.props.searchCondition.searchedProjectName}
            specification={this.pRConsolidatedReportRequestTableSpecification}
            isApprovingPage={this.props.isApprovingPage}
            onColumnWidthChange={
              this.props.onChangeWidthPRConsolidatedReportRequestTable
            }
            onSizePerPageList={(sizePerPage: number): void =>
              this.props.onSizePerPageList(sizePerPage, this.props.typeId)
            }
          />
        );
      default:
        return null;
    }
  }
}
