import { TableDraggableIcon } from "components/renewaled_ui/Table/atoms/DraggableIcon";
import { TrRef } from "components/renewaled_ui/Table/atoms/TrRef";
import { RequiredTableDataProps } from "components/renewaled_ui/Table/interface/table";
import { Cells } from "components/renewaled_ui/Table/molecules/Cells";
import React from "react";
import { Row as RowProps } from "react-table";
import { useHooks } from "./hooks";

interface Props<Data> {
  /** テーブルデータ */
  readonly data: Data[];
  /** 列 */
  readonly row: RowProps;
  /** onRowClickがあるか(行をクリック可能か) */
  readonly hasOnRowClick?: boolean;
  /** 詳細閲覧中データ */
  readonly viewingData?: Data;
  /** 行をクリックした際の遷移先(指定した場合aリンクになる) */
  readonly href?: string;
  /** 行番号 */
  readonly index: number;
  /** 列情報 */
  readonly prepareRow: (row: RowProps) => void;
  /** 行をクリックした際のハンドラ */
  readonly onRowClick?: (data: Data) => void;
  /** 行移動時のハンドラ */
  readonly onMove: (dragIndex: number, targetIndex: number) => Promise<void>;
  /** 枠線がないシンプルなデザイン */
  readonly isSimpleDesignMode: boolean;
}

/**
 * Tableの行(ドラッグ＆ドロップ対応)
 */
export const DraggableRow = <Data extends RequiredTableDataProps>(
  props: Props<Data>,
): JSX.Element => {
  const { dropTrRef, dragTdRef, isDragging, handleRowClick } = useHooks<Data>(
    props.row,
    props.prepareRow,
    props.index,
    props.onMove,
    props.onRowClick,
    props.href,
  );
  const isSelected =
    props.data[props.row.id].isSelected ||
    props.viewingData?.id === (props.row.original as Data).id;
  const isHighLight = props.data[props.row.id]?.isHighLight || false;

  return (
    <TrRef
      key={props.row.getRowProps().key}
      ref={dropTrRef}
      isSelected={isSelected}
      isHighLight={isHighLight}
      isDragging={isDragging}
      tableRowProps={props.row.getRowProps()}
      hasOnRowClick={props.hasOnRowClick}
      isSimpleDesignMode={props.isSimpleDesignMode}
    >
      <TableDraggableIcon ref={dragTdRef} />

      <Cells
        cells={props.row.cells}
        onClickCell={(id, data): void => handleRowClick(id, data as Data)}
        isSimpleDesignMode={props.isSimpleDesignMode}
      />
    </TrRef>
  );
};
