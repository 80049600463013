import ApprovingRequestsTableStorage, {
  ApprovingRequestsStorageHeaderDisplayParam,
  ApprovingRequestsStorageHeaderWidthPreReport,
} from "applications/approving_requests/utilities/ApprovingRequestsTableStorage";
import { useBulkApproval } from "applications/requests/hooks/useBulkApproval";
import RequestsTableStorage, {
  RequestsStorageHeaderDisplayParam,
  RequestsStorageHeaderWidthPreReport,
} from "applications/requests/utilities/RequestsTableStorage";
import {
  TableColumnControlCacheParams,
  TableColumnControlParam,
  TableColumnControlParams,
} from "components/renewaled_ui/ModalColumnControl/interface";
import { TableColumn } from "components/renewaled_ui/Table/interface/table";
import i18next from "i18n";
import _ from "lodash";
import React, { useState } from "react";
import { RequestsSearchPreReportResponse } from "utilities/api/responses/requests/searchResults/preReport";
import formatter from "utilities/formatter";
import { PreReportTableRow, RequestTableColumn } from "./interface";

interface UseHooks {
  readonly isShowColumnModal: boolean;
  readonly isEnableReportBulkApprove: boolean;
  readonly inProgressCreateApproveJobs: boolean;
  readonly disabledBulkApprovalButton: boolean;
  readonly selectedCount: number;
  readonly onClickShowModal: () => void;
  readonly onHideModal: () => void;
  readonly getSelectableColumns: () => TableColumnControlParam[];
  readonly getReactTableColumnParams: () => TableColumnControlParams;
  readonly getReactTableColumnParamsDefault: () => TableColumnControlParams;
  readonly onSetColumnInModal: (params: TableColumnControlCacheParams) => void;
  readonly getReports: () => PreReportTableRow[];
  readonly getHeaderColumns: () => TableColumn[];
  readonly onFetchData: (
    startIndex: number,
    sizePerPage: number,
    background: boolean,
  ) => Promise<void>;
  readonly onSelectTable: (
    data: PreReportTableRow,
    isSelected: boolean,
  ) => void;
  readonly onSelectAllTable: (isSelected: boolean) => void;
  readonly onBulkApprove: () => void;
}

interface HooksProps {
  readonly preReports: RequestsSearchPreReportResponse[];
  readonly sequenceSortable: boolean;
  readonly displayApplicant: boolean;
  readonly displayStatus: boolean;
  readonly displayButtons: boolean;
  readonly displayStatusForApprover: boolean;
  readonly isApprover: boolean;
  readonly except: string[];
  readonly currentPage: number;
  readonly sizePerPage: number;
  readonly isApprovingPage: boolean;
  readonly displayProject: boolean;
  readonly displayTemporaryPaymentDueAt: boolean;
  readonly only?: string[];
  readonly fetchData: (
    offset: number,
    limit: number,
    options?: { reset: boolean },
  ) => void;
  readonly onSortChange: (
    sortName: string,
    sortOrder: "ASC" | "DESC",
  ) => Promise<void>;
  readonly onPageChange: (page: number) => void;
  readonly onDeleteClick: (preReport: RequestsSearchPreReportResponse) => void;
  readonly onExportClick: (preReport: RequestsSearchPreReportResponse) => void;
}

/**
 * 事前申請テーブル: ロジック
 */
export const useHooks = (p: HooksProps): UseHooks => {
  /** モーダルを表示するか */
  const [isShowColumnModal, setIsShowColumnModal] = useState<boolean>(false);
  /**
   * キャッシュ: 表示するカラム
   */
  const getCacheDisplay = ():
    | ApprovingRequestsStorageHeaderDisplayParam[]
    | RequestsStorageHeaderDisplayParam[]
    | null => {
    if (p.isApprovingPage)
      return ApprovingRequestsTableStorage.data.headerDisplay.preReports;
    return RequestsTableStorage.data.headerDisplay.preReports;
  };

  /**
   * キャッシュ: カラムの横幅
   */
  const getCacheWidth = ():
    | ApprovingRequestsStorageHeaderWidthPreReport
    | RequestsStorageHeaderWidthPreReport => {
    if (p.isApprovingPage)
      return ApprovingRequestsTableStorage.data.headerWidth.preReport;
    return RequestsTableStorage.data.headerWidth.preReport;
  };

  const {
    inProgressCreateApproveJobs,
    bulkApprove,
    disabledBulkApprovalButton,
    selectedCount,
    selectedIds,
    unapprovableReportIds,
    onSelect,
    onSelectAll,
    isEnableReportBulkApprove,
  } = useBulkApproval({
    resetTable: (): void => {
      const options = {};
      const offset = 0;
      return p.fetchData(offset, p.sizePerPage, { ...options, reset: true });
    },
    requests: p.preReports.filter((r) => r !== undefined),
    type: "report",
    isApprovingPage: p.isApprovingPage,
  });

  /**
   * 一括承認を押した
   */
  const onBulkApprove = (): void => {
    bulkApprove();
  };

  /**
   * 絞り込みフィルターを非表示を押した
   */
  const onHideModal = (): void => {
    setIsShowColumnModal(false);
  };

  /**
   * 絞り込みモーダルを表示を押した
   */
  const onClickShowModal = (): void => {
    setIsShowColumnModal(true);
  };

  /**
   * カラムを表示するか
   */
  const displayColumn = (column: string): boolean => {
    let isVisible = true;
    if (p.only) {
      isVisible = _.includes(p.only, column);
    }
    if (p.except) {
      isVisible = !_.includes(p.except, column);
    }
    return isVisible;
  };

  /**
   * テーブルのヘッダーカラム
   */
  const headerColumns: RequestTableColumn[] = [
    {
      accessor: "title",
      Header: i18next.t("preReports.properties.title"),
      width: getCacheWidth().title || 100,
      minWidth: 10,
      defaultCanSort: false,
      isShow: displayColumn("title"),
    },
    {
      accessor: "sequence",
      Header: i18next.t("preReports.properties.sequence"),
      width: getCacheWidth().sequence || 100,
      minWidth: 10,
      defaultCanSort: p.sequenceSortable,
      isShow: displayColumn("sequence"),
    },
    {
      accessor: "approvalFlowName",
      Header: i18next.t("reports.properties.approvalFlowName"),
      width: getCacheWidth().approvalFlowName || 100,
      minWidth: 10,
      defaultCanSort: false,
      isShow: p.isApprover && displayColumn("approvalFlowName"),
    },
    {
      accessor: "preAmount",
      Header: i18next.t("preReports.properties.preAmount"),
      width: getCacheWidth().preAmount || 100,
      minWidth: 10,
      defaultCanSort: false,
      dataAlign: "right",
      isShow: displayColumn("preAmount"),
    },
    {
      accessor: "department",
      Header: i18next.t("transactions.properties.userDepartment"),
      width: getCacheWidth().department || 100,
      minWidth: 10,
      defaultCanSort: false,
      isShow: displayColumn("department"),
    },
    {
      accessor: "applicant",
      Header: i18next.t("reports.properties.applicantName"),
      width: getCacheWidth().applicant || 100,
      minWidth: 10,
      defaultCanSort: false,
      isShow: p.displayApplicant && displayColumn("applicant"),
    },
    {
      accessor: "project",
      Header: i18next.t("projects.project"),
      width: getCacheWidth().project || 100,
      minWidth: 10,
      defaultCanSort: false,
      isShow: p.displayProject && displayColumn("projectsInPreReport"),
    },
    {
      accessor: "temporaryPaymentDueAt",
      Header: i18next.t("reports.properties.temporaryPaymentDueAt"),
      width: getCacheWidth().temporaryPaymentDueAt || 100,
      minWidth: 10,
      defaultCanSort: false,
      isShow:
        p.displayTemporaryPaymentDueAt &&
        displayColumn("temporaryPaymentDueAt"),
    },
    {
      accessor: "statusForApprover",
      Header: i18next.t("reports.properties.statusForApprover"),
      width: getCacheWidth().statusForApprover || 100,
      minWidth: 10,
      defaultCanSort: false,
      isShow: p.displayStatusForApprover && displayColumn("statusForApprover"),
    },
    {
      accessor: "status",
      Header: i18next.t("reports.properties.status"),
      width: getCacheWidth().status || 100,
      minWidth: 10,
      defaultCanSort: false,
      isShow: p.displayStatus && displayColumn("status"),
    },
  ];

  /**
   * テーブルのヘッダーカラム: 表示するカラムのみの配列
   */
  const getShowHeaderColumns = (): RequestTableColumn[] =>
    headerColumns.filter((c) => c.isShow);

  /**
   * テーブルのヘッダーカラム: 末尾に追加され表示形式を変更できない
   */
  const headerColumnsAdded: RequestTableColumn[] = [
    {
      accessor: "buttons",
      Header: "",
      width: getCacheWidth().buttons || 100,
      minWidth: 10,
      defaultCanSort: false,
      isShow: p.displayButtons,
    },
  ];

  /**
   * 表示するカラムのデフォルト値
   */
  const displayAccessorsDefault = (): string[] => {
    const cacheDisplays = getCacheDisplay();
    const showColumns = getShowHeaderColumns();
    // キャッシュがなければそのまま表示する
    if (!cacheDisplays) return showColumns.map((h) => h.accessor);

    // キャッシュがある場合
    // キャッシュしてる項目を後に表示しない権限に変わる可能性があるため、表示項目に一致した場合のみ反映する
    const showCacheColumns = showColumns.filter((c) => {
      const cacheColum = cacheDisplays.find((d) => d.accessor === c.accessor);
      if (!cacheColum) return true;
      return cacheColum.isShow;
    });
    const cacheShowDisplays = showCacheColumns.map((c) => {
      return (
        cacheDisplays.find((d) => d.accessor === c.accessor) || {
          accessor: c.accessor,
          index: 0,
        }
      );
    });
    const sorterdCacheColumns = cacheShowDisplays.sort(
      (a, b) => a.index - b.index,
    );
    return sorterdCacheColumns.map((c) => c.accessor);
  };

  /**
   * 表示順序と表示するカラムの一覧
   */
  const [displayAccessors, setDisplayAccessors] = useState<string[]>(
    displayAccessorsDefault(),
  );

  /**
   * ヘッダーのカラムを返す
   */
  const getHeaderColumns = (): TableColumn[] => {
    const showColumns = getShowHeaderColumns();
    const filterColumns: RequestTableColumn[] = displayAccessors.map(
      (accessor) => {
        return (
          showColumns.find((s) => s.accessor === accessor) || {
            accessor,
            Header: "",
            width: 0,
            minWidth: 0,
            defaultCanSort: false,
            isShow: false,
            dataAlign: "left",
          }
        );
      },
    );

    // ボタンなどの末尾固定のカラム
    const showAddedColumns = headerColumnsAdded.filter((c) => c.isShow);
    const mergedColumns = [...filterColumns, ...showAddedColumns];

    return mergedColumns.map(
      (c): TableColumn => ({
        accessor: c.accessor,
        Header: c.Header,
        width: c.width,
        minWidth: c.minWidth,
        defaultCanSort: c.defaultCanSort,
        dataAlign: c.dataAlign,
      }),
    );
  };

  /**
   * データ取得のハンドラ
   */
  const onFetchData = async (
    startIndex: number,
    sizePerPage: number,
    background: boolean,
  ): Promise<void> => {
    await p.fetchData(startIndex, sizePerPage, { reset: !background });
  };

  /**
   * チェックボックスで全選択を押した
   */
  const onSelectAllTable = (isSelected: boolean): void => {
    const displayRows = p.preReports.slice(
      (p.currentPage - 1) * p.sizePerPage,
      p.currentPage * p.sizePerPage,
    );
    const filterdRows = displayRows.filter((r) => {
      if (!r) return false;
      if (unapprovableReportIds.includes(r.id)) return false;
      return true;
    });
    // 表示中の行データのみを選択する
    onSelectAll(filterdRows, isSelected);
  };

  /**
   * チェックボックスを押した
   */
  const onSelectTable = (
    data: PreReportTableRow,
    isSelected: boolean,
  ): void => {
    onSelect(data.response, isSelected);
  };

  /**
   * 削除ボタンのJSXを返す
   */
  const renderDeleteButton = (
    preReport: RequestsSearchPreReportResponse,
  ): JSX.Element | null => {
    const deletable = _.get(preReport, "permission.deletable", false);

    return !_.isNil(p.onDeleteClick) ? (
      <span>
        <button
          className={`btn btn-danger${deletable ? "" : " disabled"}`}
          onClick={(e): void => {
            e.stopPropagation();
            p.onDeleteClick(preReport);
          }}
        >
          {i18next.t("commons.actions.delete")}
        </button>
      </span>
    ) : null;
  };

  /**
   * ボタンのJSXを返す
   */
  const getButtonGroupJSX = (
    preReport: RequestsSearchPreReportResponse,
  ): JSX.Element => {
    return (
      <div className="report-btn-group row">
        <span>
          <button
            className="btn btn-accent"
            onClick={(e): void => {
              e.stopPropagation();
              p.onExportClick(preReport);
            }}
          >
            {i18next.t("commons.actions.output")}
          </button>
        </span>
        {renderDeleteButton(preReport)}
      </div>
    );
  };

  /**
   * テーブルの表示内容を整形する
   */
  const getReports = (): PreReportTableRow[] =>
    p.preReports.map((r): PreReportTableRow => {
      if (_.isNil(r)) return r;

      const statusForApprover = formatter.requestStatus({
        status: r.statusForApprover,
        label: r.statusForApprover,
        color: r.statusColorForApprover,
      });
      const status = formatter.requestStatus(r.overallStatus);
      const isSelected = selectedIds.includes(r.id);
      const isUnapprovable = unapprovableReportIds.includes(r.id);

      return {
        id: r.id,
        title: r.title,
        sequence: r.sequence,
        approvalFlowName: r.approvalFlowName,
        applicant: formatter.text(r.user.name, ""),
        project: formatter.project(r.project, "", null, ["name", "displayId"]),
        department: formatter.text(r.department?.name, ""),
        temporaryPaymentDueAt: formatter.date(r.temporaryPaymentDueAt, {
          withDayOfTheWeek: true,
        }),
        preAmount: formatter.amount(r.preAmount),
        statusForApprover,
        status,
        buttons: getButtonGroupJSX(r),
        isSelected,
        isUnapprovable,
        response: r,
      };
    });

  /**
   * テーブルの表示内容を整形する
   */
  const getSelectableColumns = (): TableColumnControlParam[] => {
    return getShowHeaderColumns().map((h, index) => ({
      accessor: h.accessor,
      selectable: true,
      show: h.isShow,
      index,
      Header: h.Header,
    }));
  };

  /**
   * モーダルで、カラムの表示非表示を取得する
   */
  const getReactTableColumnParams = (): TableColumnControlParams => {
    const cacheDisplays = getCacheDisplay();
    if (!cacheDisplays) {
      // キャッシュがなければそのままカラムを表示する
      const arr = getShowHeaderColumns().map((h, index) => [
        h.accessor,
        {
          show: h.isShow,
          index,
        },
      ]);
      return Object.fromEntries(arr);
    }
    const arr = cacheDisplays.map((c) => [
      c.accessor,
      {
        show: c.isShow,
        index: c.index,
      },
    ]);
    return Object.fromEntries(arr);
  };

  /**
   * モーダルで、カラムの表示非表示の初期値を取得する
   */
  const getReactTableColumnParamsDefault = (): TableColumnControlParams => {
    // キャッシュがなければそのままカラムを表示する
    const arr = getShowHeaderColumns().map((h, index) => [
      h.accessor,
      {
        show: h.isShow,
        index,
      },
    ]);
    return Object.fromEntries(arr);
  };

  /**
   * モーダルで、表示順序と表示するものを変更した
   */
  const onSetColumnInModal = (params: TableColumnControlCacheParams): void => {
    const displayColumns = Object.keys(params).map((accessor) => ({
      accessor,
      index: params[accessor].index || 0,
      isShow: params[accessor].show,
    }));
    const sorterdColumns = displayColumns.sort((a, b) => a.index - b.index);

    // 現在の表示項目を更新する
    setDisplayAccessors(
      sorterdColumns.filter((c) => c.isShow).map((s) => s.accessor),
    );

    // キャッシュに保存する
    if (p.isApprovingPage) {
      ApprovingRequestsTableStorage.set({
        sizePerPage: ApprovingRequestsTableStorage.data.sizePerPage,
        headerWidth: ApprovingRequestsTableStorage.data.headerWidth,
        headerDisplay: {
          ...ApprovingRequestsTableStorage.data.headerDisplay,
          preReports: sorterdColumns,
        },
      });
    } else {
      RequestsTableStorage.set({
        sizePerPage: RequestsTableStorage.data.sizePerPage,
        headerWidth: RequestsTableStorage.data.headerWidth,
        headerDisplay: {
          ...RequestsTableStorage.data.headerDisplay,
          preReports: sorterdColumns,
        },
      });
    }
  };

  return {
    isShowColumnModal,
    isEnableReportBulkApprove,
    inProgressCreateApproveJobs,
    onBulkApprove,
    disabledBulkApprovalButton,
    selectedCount,
    getSelectableColumns,
    getReactTableColumnParams,
    getReactTableColumnParamsDefault,
    onSetColumnInModal,
    getReports,
    getHeaderColumns,
    onClickShowModal,
    onHideModal,
    onFetchData,
    onSelectTable,
    onSelectAllTable,
  };
};
